import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import getColorFromField from '@helpers/getColorFromField';
import ButtonCms from '@components/atoms/ButtonCms';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import Card from '@components/molecules/Card';
import HeadlineAndCallToAction from '@components/molecules/HeadlineAndCallToAction';

const ProductRow = ({
  data: { tagline, title, cta, products, brandColor }
}) => {
  const color = getColorFromField(brandColor);

  return (
    <Section>
      <Wrapper>
        <HeadlineAndCallToAction
          title={title}
          tagline={tagline}
          color={color}
          cta={
            cta && (
              <ButtonCms
                data={cta}
                color={color}
                dataLayerType="section_title"
              />
            )
          }
          mb={{ base: 6, md: 10 }}
        />
        <Grid templateColumns="repeat(auto-fit, minmax(9rem, 1fr))" gap={8}>
          {products.map((product) => (
            <GridItem key={`product-row-product-${product.id}}`}>
              <Card
                data={{
                  strapiComponent: `elements.product-card`,
                  frontFace: product.frontFace,
                  backFace: product.backFace,
                  title: product.title,
                  cta: product.cta,
                  color
                }}
                sx={{
                  height: `100%`,
                  '.card-content': {
                    height: `100%`,
                    display: `flex`
                  },
                  '.card-cta-wrapper': {
                    display: `flex`,
                    padding: `0`
                  },
                  '.card-cta': {
                    marginTop: `auto`
                  },
                  '.card-cta-title': {
                    fontSize: { base: `lg`, lg: `2xl` }
                  }
                }}
              />
            </GridItem>
          ))}
        </Grid>
      </Wrapper>
    </Section>
  );
};

export default ProductRow;
