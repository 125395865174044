/* eslint-disable function-paren-newline */
import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Flex,
  Grid,
  GridItem,
  SimpleGrid,
  Heading,
  Tabs as ChakraTabs,
  TabList,
  Tab,
  Text,
  TabPanels,
  TabPanel,
  useBreakpointValue
} from '@chakra-ui/react';
import Wrapper from '@components/atoms/Wrapper';
import Button from '@components/atoms/Button';
import MondelezCard from '@components/molecules/MondelezCard';
import { stringToId } from '../../helpers/stringFormatters';

const MondelezTabs = ({ data: { title, tagline, tabList } }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const tabTitles = tabList.map((tab) => tab.title);
  const tabContent = tabList.map((tab) =>
    tab.tabsContent.map((content) => content)
  );

  let currentIndex = 0;
  const handleAccordionClick = (event) => {
    const selectedTab = parseInt(event.target.getAttribute(`tab-index`), 10);
    setTabIndex(selectedTab);
  };

  const handleAccordionChange = (state) => {
    if (state > -1) {
      const paddedIndex = tabContent.reduce(
        (accumulator, currentTabItems, index) => {
          return index < state
            ? accumulator + currentTabItems.length
            : accumulator;
        },
        0
      );

      setTabIndex(paddedIndex);
    }
  };

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Wrapper>
      <Flex w="100%" justifyContent="space-between" alignItems="flex-end">
        <Box>
          <Heading variant="detail" size={[`xs`]} color="secondary.mid-grey">
            {tagline}
          </Heading>
          <Heading
            fontSize={[`2xl`, `3xl`, undefined, `5xl`]}
            mb={{ base: `6`, lg: `12` }}>
            {title}
          </Heading>
        </Box>
      </Flex>
      <Grid
        gridTemplateColumns={{ base: `100%`, lg: `18rem 1fr` }}
        columnGap={{ lg: `8`, xl: `16` }}>
        <GridItem position="relative">
          <Accordion
            id="accordion-tabs-long-items"
            allowToggle={!isMobile}
            defaultIndex={0}
            overflowX={{ base: `scroll`, md: `hidden` }}
            overflowY="hidden"
            w={{ base: `100%`, lg: `72` }}
            display={{ base: `flex`, lg: `block` }}
            onChange={handleAccordionChange}>
            {tabTitles.map((tabTitle, index) => {
              return (
                <AccordionItem
                  id={`accordion-tabs-long-item-${stringToId(
                    tabTitle
                  )}-${index}`}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`accordion-tabs-long-item-${stringToId(
                    tabTitle
                  )}-${index}`}
                  border="none">
                  {({ isExpanded }) => {
                    currentIndex = tabContent.slice(0, index).flat().length;
                    return (
                      <>
                        <AccordionButton
                          fontFamily="heading"
                          fontWeight="bold"
                          fontSize="lg"
                          color="primary.dark-blue"
                          borderRadius="xs"
                          mb={{ base: `16`, lg: `0` }}
                          w={{ base: `max-content`, lg: `100%` }}
                          backgroundColor={
                            isExpanded ? `secondary.pale-blue` : null
                          }
                          _hover={{
                            backgroundColor: isExpanded
                              ? `secondary.pale-blue`
                              : `secondary.light-grey`
                          }}>
                          <Flex
                            justifyContent="space-between"
                            w="100%"
                            textAlign="left">
                            <Text mb="0">{tabTitle}</Text>
                            {!isMobile && <AccordionIcon />}
                          </Flex>
                        </AccordionButton>
                        <AccordionPanel
                          position={{ base: `absolute`, lg: `static` }}
                          top="12"
                          left="0"
                          display={{ base: `flex`, lg: `block` }}
                          w={{ base: `100%`, lg: null }}
                          overflowX={{ base: `scroll`, md: `hidden` }}
                          columnGap="4">
                          {tabContent[index].map((tabSubtitle) => {
                            currentIndex += 1;
                            return (
                              <Button
                                key={`${tabTitle}-${tabSubtitle.title}-${currentIndex}`}
                                variant="secondary"
                                fontFamily="body"
                                fontWeight="0"
                                fontSize="md"
                                w="100%"
                                mb="3"
                                justifyContent="left"
                                color={
                                  tabIndex === currentIndex - 1
                                    ? `primary.dark-blue`
                                    : `secondary.mid-grey`
                                }
                                _hover={{ color: `primary.dark-blue` }}
                                sx={{ '& > span': { pointerEvents: `none` } }}
                                tab-index={currentIndex - 1}
                                onClick={handleAccordionClick}>
                                {tabSubtitle.title}
                              </Button>
                            );
                          })}
                        </AccordionPanel>
                      </>
                    );
                  }}
                </AccordionItem>
              );
            })}
          </Accordion>
        </GridItem>
        <GridItem>
          <ChakraTabs id="chakratabs-tabs-long-items" index={tabIndex}>
            <TabList id="tablist-tabs-long-items" display="none">
              {tabContent.map((subTabList) =>
                subTabList.map((subTab, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Tab key={`tab-${subTab.title}-${i}`}>{subTab.title}</Tab>
                ))
              )}
            </TabList>
            <TabPanels>
              {tabContent.map((subTabList, index) =>
                subTabList.map((subTab) => (
                  <TabPanel key={`tab-panel-${subTab.title}`}>
                    <Box>
                      <Heading
                        variant="detail"
                        size={[`xs`]}
                        color="secondary.mid-grey">
                        {tabTitles[index]}
                      </Heading>
                      <Heading>{subTab.title}</Heading>
                      <SimpleGrid
                        columns={{ base: 1, md: 3, lg: 4 }}
                        spacing={8}>
                        {subTab.productListing.map((product) => (
                          <GridItem key={`product-row-product-${product.id}}`}>
                            <MondelezCard
                              data={{
                                strapiComponent: `elements.product-card`,
                                frontFace: product.frontFace,
                                backFace: product.backFace,
                                video: product.video,
                                title: product.title,
                                cta: product.cta
                              }}
                            />
                          </GridItem>
                        ))}
                      </SimpleGrid>
                    </Box>
                  </TabPanel>
                ))
              )}
            </TabPanels>
          </ChakraTabs>
        </GridItem>
      </Grid>
    </Wrapper>
  );
};

export default MondelezTabs;
