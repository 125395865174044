import React from 'react';
import { Box, Heading, Flex } from '@chakra-ui/react';
import { height as headerHeight } from '@components/organisms/Header';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import CallToActions from '@components/molecules/CallToActions';
import RoundEdge from '@components/atoms/RoundEdge';
import { localeRoot } from '@helpers/environment';
import { appendIfValid } from '@helpers/linkResolver';

const HomeHero = ({ data: { title, image, videoFile, ctas } }) => {
  console.log(`videoFile; `, videoFile);
  return (
    <Box
      as="section"
      position="relative"
      bg="primary.froneri-blue"
      height={{ md: `100vh` }}
      minHeight={{ md: `44rem` }}
      maxHeight={{ md: `56.25rem` }}
      pt={headerHeight}
      mb={{ md: 24 }}>
      <Flex
        position={{ md: `absolute` }}
        top="0"
        right="0"
        width={{ base: `100%`, md: `50%`, lg: `calc(50% + 6.25rem)` }}
        height={{ base: `100vw`, md: `100%` }}
        mt={{ base: `-1.25rem`, md: 0 }}
        borderTopLeftRadius={{ md: `sm` }}
        borderBottomLeftRadius={{ md: `lg` }}
        overflow="hidden"
        sx={{
          '> .gatsby-image-wrapper': {
            flexGrow: 1
          }
        }}>
        {videoFile && (
          <Box
            as="video"
            src={`${appendIfValid(localeRoot, ``)}${videoFile.url}`}
            controls={false}
            autoPlay
            loop
            muted
            playsInline
            objectFit="cover"
            width="100%"
            height="100%"
          />
        )}
        {!videoFile && image && <Image image={image} loading="eager" />}
      </Flex>
      <Flex
        position="relative"
        align="flex-end"
        height="100%"
        pt={{ base: 16, md: 0 }}
        pb={{ base: 4, md: 14 }}
        mt={{ base: `-1.5rem`, md: 0 }}
        background={{ base: `primary.froneri-blue`, md: `none` }}
        borderTopLeftRadius={{ base: `sm`, md: `none` }}
        borderTopRightRadius={{ base: `sm`, md: `none` }}>
        <Wrapper>
          <Box width={{ md: `50%`, lg: `calc(50% - 6.25rem)` }} pr={{ md: 10 }}>
            {title && (
              <Heading as="h1" size="4xl" mb="0">
                {title}
              </Heading>
            )}
            {ctas && ctas.length > 0 && (
              <CallToActions
                ctas={ctas}
                primaryColor="white"
                secondaryIconBorderColor="white"
                mt={{ base: 6, md: 8 }}
                dataLayerType="home_hero"
              />
            )}
          </Box>
        </Wrapper>
      </Flex>
      <RoundEdge display={{ base: `block`, md: `none` }} />
    </Box>
  );
};

export default HomeHero;
